const currencies = {
  BRL: {
    code: 'BRL',
    prefix: 'R$',
    decimalSeparator: ',',
    groupSeparator: '.',
    display: 'Real (BRL)'
  },
  USD: {
    code: 'USD',
    prefix: '$',
    decimalSeparator: '.',
    groupSeparator: ',',
    display: 'Dólar (USD)'
  },
  EUR: {
    code: 'EUR',
    prefix: '€',
    decimalSeparator: '.',
    groupSeparator: ',',
    display: 'Euro (EUR)'
  },
  MXN: {
    code: 'MXN',
    prefix: 'M$',
    decimalSeparator: '.',
    groupSeparator: ',',
    display: 'Peso Mexicano (MXN)'
  },
  PERCENT: {
    code: '%',
    suffix: '%',
    decimalSeparator: ',',
    groupSeparator: '.',
    decimalPlaces: 2,
    display: 'Porcentagem (%)'
  },
  GBP: {
    code: 'GBP',
    prefix: '£',
    decimalSeparator: '.',
    groupSeparator: ',',
    display: 'Libra Esterlina (GBP)'
  },
  ARS: {
    code: 'ARS',
    prefix: '$',
    decimalSeparator: '.',
    groupSeparator: ',',
    display: 'Peso Argentino (ARS)'
  },
  CHF: {
    code: 'CHF',
    prefix: 'CHF',
    decimalSeparator: '.',
    groupSeparator: ',',
    display: 'Franco Suíço (CHF)'
  },
  GTQ: {
    code: 'GTQ',
    prefix: 'Q',
    decimalSeparator: '.',
    groupSeparator: ',',
    display: 'Quetzal (GTQ)'
  },
  JPY: {
    code: 'JPY',
    prefix: '¥',
    decimalSeparator: '.',
    groupSeparator: ',',
    display: 'Iene Japonês (JPY)'
  },
  PEN: {
    code: 'PEN',
    prefix: 'S/.',
    decimalSeparator: '.',
    groupSeparator: ',',
    display: 'Sol Peruano (PEN)'
  },
  SEK: {
    code: 'SEK',
    prefix: 'kr',
    decimalSeparator: '.',
    groupSeparator: ',',
    display: 'Coroa Sueca (SEK)'
  },
  NOK: {
    code: 'NOK',
    prefix: 'kr',
    decimalSeparator: '.',
    groupSeparator: ',',
    display: 'Coroa Noruegueza (NOK)'
  },
  PLN: {
    code: 'PLN',
    prefix: 'zl',
    decimalSeparator: '.',
    groupSeparator: ',',
    display: 'Zlot Polaco (PLN)'
  }
};

export default currencies;

export const costCurrencies = ['BRL', 'USD', 'EUR', 'MXN', '%'];
