import React from 'react';
import { Select } from 'antd';

const SelectServiceInvoiceLocation = ({ ...rest }) => {
  return (
    <Select {...rest} defaultValue={"companyMunicipality"}>
      <Select.Option value="companyMunicipality" >
        No município da empresa
      </Select.Option>
      <Select.Option value="customerMunicipality">
        No município do cliente
      </Select.Option>
    </Select>
  );
};

export default SelectServiceInvoiceLocation;
