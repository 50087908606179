const jobStatus = {
  scheduled: {
    display: 'Agendada',
    color: 'default'
  },
  enqueued: {
    display: 'Enfileirada',
    color: 'cyan'
  },
  processing: {
    display: 'Processando',
    color: 'processing'
  },
  succeced: {
    display: 'Sucesso',
    color: 'green'
  },
  failed: {
    display: 'Falha',
    color: 'red'
  },
  removed: {
    display: 'Removido',
    color: 'red'
  }
};

export { jobStatus };
