import React from 'react';
import { Select } from 'antd';

const SelectTaxationType = ({ ...rest }) => {
  return (
    <Select {...rest}>
      <Select.Option value="taxationInMunicipality">
        Tributado no Município
      </Select.Option>
      <Select.Option value="taxationOutsideMunicipality">
        Tributado em outro município
      </Select.Option>
      <Select.Option value="exemption">Isento</Select.Option>
      <Select.Option value="immune">Imune</Select.Option>
      <Select.Option value="suspendedByCourt">
        Suspenso por Decisão Judicial
      </Select.Option>
      <Select.Option value="suspendedByAdministrativeProcedure">
        Suspenço por Decisão Administrativa
      </Select.Option>
      <Select.Option value="exportation">Exportação</Select.Option>
      <Select.Option value="nonIncidence">Não incidência</Select.Option>
    </Select>
  );
};

export default SelectTaxationType;
